<template>
  <!-- 收盘价配置 -->
  <div>
    <!-- 左上角新增按钮 -->
    <div class="btn">
      <el-button type="danger" size="mini" @click="onClosePriceaddOrEdits"
        >新增</el-button
      >
    </div>
    <!-- 表格区域 -->
    <div>
      <el-table :data="data">
        <el-table-column
          prop="id"
          label="数字通证"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="time"
          label="时间配置"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="rule"
          label="规则"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="onBtn" @click="onBtn(scope.row.id)">编辑</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
      total: null,
      currentPage: 1,
      pageNumber: null,
    };
  },
  created() {
    this.axios.get("/admin/closing/findAll", {}).then((res) => {
      console.log(res);
      if (res.data.code == 200) {
        this.data = res.data.data.records;
        this.total = res.data.data.total;
        this.currentPage = res.data.data.current;
        this.pageNumber = res.data.data.size;
      }
    });
  },
  methods: {
    //   编辑
    onBtn(id) {
      this.$router.push({ path: "addclosingPrice", query: { id } });
    },
    // 点击跳转新增收盘价配置
    onClosePriceaddOrEdits() {
      console.log(123);
      this.$router.push("closePriceaddOrEdit");
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageNumber = val;
      this.axios
        .get("/admin/closing/findAll", {
          pageNumber: this.currentPage,
          pageSize: this.pageNumber,
        })
        .then((res) => {
          this.data = res.data.data.records;
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.axios
        .get("/admin/closing/findAll", {
          currentPage: this.currentPage,
          pageSize: this.pageNumber,
        })
        .then((res) => {
          this.data = res.data.data.records;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  padding: 20px;
}
.onBtn {
  font-size: 12px;
  color: #409eff;
}
.onBtn:hover {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
